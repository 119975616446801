import { throttle } from './helpers'

export default function NavScroll () {
  let whenToChange = window.innerHeight

  function getWindowHeight () {
    whenToChange = window.innerHeight
  }

  function handleScroll () {
    let pixelsFromTop = window.scrollY
    if (pixelsFromTop! > whenToChange) {
      $('[dk-nav-scroll]').addClass('navscrolled')
    } else {
      $('[dk-nav-scroll]').removeClass('navscrolled')
    }
  }

  document.addEventListener('scroll', throttle(handleScroll, 100))
  window.addEventListener('resize', throttle(getWindowHeight, 100))
  getWindowHeight()
  handleScroll()
}
