import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { startDKApp } from '@core/app'
import ConvertTags from './app/modules/convertTags'
import Dialog from './app/modules/dialog'
import Anchors from './app/modules/anchors'
import Videos from './app/modules/videos'
import NavScroll from './app/modules/navScroll'
import CurrentYear from './app/modules/currentYear'
import ContactForm from './app/components/ContactForm'
import { bootstrap } from '@core/bootstrap'

startDKApp(() => {
  ConvertTags()
  Dialog()
  Anchors()
  Videos()
  NavScroll()
  CurrentYear()
  bootstrap('contact-form', [ContactForm], {})
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
